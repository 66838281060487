import { getRequest, postRequest, deleteRequest, putRequest } from "./utility";
import axios from "axios";

/**
 * Gets the list of users
 */
export function getUsers(successCallback, errorCallback) {
  const url = window.APP_CONFIG.URL_API_ADMIN_V1 + "users";
  getRequest(axios, url, successCallback, errorCallback);
}

/**
 * Update a user
 */
export function updateUser(
  element,
  userId,
  data,
  successCallback,
  errorCallback
) {
  const url = window.APP_CONFIG.URL_API_ADMIN_V1 + "user" + "/" + userId;
  postRequest(element.axios, url, data, successCallback, errorCallback);
}

/**
 * Delete a user
 */
export function deleteUser(element, userId, successCallback, errorCallback) {
  const url = window.APP_CONFIG.URL_API_ADMIN_V1 + "user" + "/" + userId;
  deleteRequest(element.axios, url, null, successCallback, errorCallback);
}

/**
 * Create a user
 */
export function createUser(element, params, successCallback, errorCallback) {
  const url = window.APP_CONFIG.URL_API_ADMIN_V1 + "user";
  putRequest(element.axios, url, params, successCallback, errorCallback);
}

/**
 * Resend an invitation
 */
export function resendInvitation(
  element,
  email,
  successCallback,
  errorCallback
) {
  const url = window.APP_CONFIG.URL_API_ADMIN_V1 + "user" + "/validation/email";
  const params = { email };
  postRequest(element.axios, url, params, successCallback, errorCallback);
}

/**
 * Lock or unlock a user
 */
export function lockUser(
  element,
  userId,
  toLock,
  successCallback,
  errorCallback
) {
  let url = window.APP_CONFIG.URL_API_ADMIN_V1 + "user" + "/" + userId + "/";
  url += toLock ? "lock" : "unlock";
  postRequest(element.axios, url, null, successCallback, errorCallback);
}
