<template>
  <div class="sidebar-top">
    <div class="d-flex sidebar-top flex-column align-items-center py-2">
      <SwLogo />
      <div class="sidebar-top__title">Manager - {{ appVersion }}</div>
    </div>
  </div>
</template>

<script>
import SwLogo from "../icons/sw-logo.vue";

export default {
  data() {
    return {
      appVersion: window.APP_CONFIG.APP_VERSION,
    };
  },
  components: { SwLogo },
};
</script>

<style scoped lang="scss">
.sidebar-top {
  padding-left: 5px;
  background-color: var(--sw-secondary-green-dark);
  color: white;
  &__title {
    color: white;
  }
}
</style>
